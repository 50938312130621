@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;900&family=Poppins:wght@300;400;700&family=Pacifico&display=swap");

/* font-family: 'Lato', sans-serif; */
/* font-family: 'Poppins', sans-serif; */
*,
html,
body,
::after,
::before {
 /* min-height: 100%; */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  line-height: 1.6;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
